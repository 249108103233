import { useEffect, useState } from "react";
import Dropdowns, { closeDropdown } from "components/dropdowns";

import { useDispatch, useSelector } from "react-redux";
import SelectOrderer from "pages/booking/components/SelectOrderer";
import { useFormik } from "formik";

import { changeOrdererAPI } from "utils/api/booking"; 

import * as Yup from 'yup';
import Buttons from "components/buttons";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { handleGetDetailOrder } from "stores/actions/booking";

const UpdateBooker = () => {
  
  const dispatch = useDispatch()

  const auth = useSelector(({auth}) => auth)

  const { bookingDetail } = useSelector((state) => state.booking);

  const [isLoading, setIsLoading] = useState(false)
  const [selectMeStatus, setSelectMeStatus] = useState(false)
  const [pemesanSelectorLabel, setPemesanSelectorLabel] = useState("Pilih Pemesan")

  const formik = useFormik({
    initialValues: {
      id: null,
      role: 'admin'
    },
    validationSchema: Yup.object().shape({
      "id": Yup.number().required("Pemesan harus diisi"),
      "role": Yup.string().required("Role harus diisi"),
    })
  })

  const handleSelectPemesan = (pemesan, role) => {

    formik.setFieldValue("id", pemesan.id)
    formik.setFieldValue("role", role)

    setPemesanSelectorLabel(`${pemesan.title ? `${pemesan.title.toUpperCase()}.` : ''} ${pemesan.name.toUpperCase()}`)
    setSelectMeStatus(false)
  }

  const handleSelectMe = () => {

    formik.setFieldValue("id", auth.user.id)
    formik.setFieldValue("role", "admin")

    setPemesanSelectorLabel(`${auth.user.title ? `${auth.user.title.toUpperCase()}.` : ''} ${auth.user.name.toUpperCase()}`)
    setSelectMeStatus(true)
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const res = await changeOrdererAPI(bookingDetail.order_number, formik.values)

    setIsLoading(false)

    if(res.status === 200 || res.status === 201) {
      dispatch(handleSuccess(res))
      dispatch(handleGetDetailOrder(bookingDetail.order_number));
      // onSubmited()
    } else {
      dispatch(handleError(res.data))
    }

  }

  useEffect(() => {
    formik.setFieldValue("id", bookingDetail?.pemesan.id)
    formik.setFieldValue("role", bookingDetail?.guard_type)

    setPemesanSelectorLabel(`${bookingDetail?.pemesan.title ? `${bookingDetail?.pemesan.title.toUpperCase()}.` : ''} ${bookingDetail?.pemesan.name.toUpperCase()}`)
    setSelectMeStatus(false)
  
  }, [bookingDetail])

  return (
    <>
      <div className="border rounded-2xl">
        <div className="p-4">
          <div className="text-sm font-semibold">
            Pemesan { !selectMeStatus &&  <a className="inline-block text-red-1 cursor-pointer" onClick={handleSelectMe}>pilih diri saya</a> }
          </div>
          <Dropdowns
            customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center`}
            placement="bottom"
            labelDropdown={pemesanSelectorLabel}
            dropdownArrow={true}
          >
              <SelectOrderer handleChange={(orderer, role) => {
                handleSelectPemesan(orderer, role)
                closeDropdown()
              }} />
          </Dropdowns>
        </div>
        <div className="border-t">
                <div className="p-4 flex justify-end">
                    <Buttons
                        disabled={!(formik.isValid && formik.dirty)}
                        customClass="btn-primary btn-large"
                        onClick={handleSubmit}
                        loading={isLoading}
                        text="Simpan"
                        />
                </div>
            </div>
      </div>
    </>
  )
}

export default UpdateBooker