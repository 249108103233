import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import Switch from 'components/form/switch'
import { toPermalink } from 'utils/helpers'

const Family = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber,
    author
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }

    const marriedStatusOptions = [
        {
            label: 'Sudah Menikah',
            value: 'Sudah Menikah'
        },
        {
            label: 'Belum Menikah',
            value: 'Belum Menikah'
        },
        {
            label: 'Cerai Hidup',
            value: 'Cerai Hidup'
        },
        {
            label: 'Cerai Mati',
            value: 'Cerai Mati'
        }
    ]

    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })

    }, [data])



    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div className='flex gap-4'>
                    <div className='w-1/2'>
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            name='data.father_name'
                            id='data.father_name'
                            label='Nama Ayah'
                            placeholder='Nama Ayah'
                            containerClass='mb-4'
                            value={formik.values?.data?.family_father_name}
                            onChange={(e) => formik.setFieldValue('data.family_father_name', e.target.value)}
                            />
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            label='Nama Ibu'
                            name='data.mother_name'
                            id='data.mother_name'
                            placeholder='Masukan nama Ibu'
                            containerClass='mb-4'
                            value={formik.values?.data?.family_mother_name}
                            onChange={(e) => formik.setFieldValue('data.family_mother_name', e.target.value)}
                            />
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            label='No KK'
                            name='data.kk_number'
                            id='data.kk_number'
                            placeholder='Masukan No KK'
                            containerClass='mb-4'
                            maxLength={16}
                            value={formik.values?.data?.kk_number}
                            onChange={(e) => formik.setFieldValue('data.kk_number', e.target.value)}
                            />
                    </div>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest')}
                            type='chose'
                            label="Foto KK"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.kk}
                            onChange={(url) => formik.setFieldValue('data.kk', url)}
                            handleRemove={() => formik.setFieldValue('data.kk', null)}
                            customName={`kk-${orderNumber}-${toPermalink(jamaahName)}`}
                            note={
                                author?.kk
                                    ? `Terupload ${moment(author?.kk?.updated_at).format('dddd, DD MMMM YYYY [.] [jam] HH.mm')} oleh ${author?.kk?.name} sebagai ${author?.kk?.role}` 
                                    : null
                            }
                            />
                    </div>
                    
                </div>
                <div className='mb-4 mt-4'>                
                    <Selects
                        label="Status Pernikahan"
                        placeholder="Pilih Status Pernikahan"
                        iconPosition="left"
                        options={marriedStatusOptions}
                        values={marriedStatusOptions.filter((x) => x.value === formik.values?.data?.family_married_status)}
                        onChange={(value) => formik.setFieldValue('data.family_married_status', value[0]?.value)}
                    />
                </div>
                {formik.values?.data?.family_married_status == "Sudah Menikah" && (
                    <>
                        <div>
                            <UploadArea
                                viewOnly={!permissions.includes('update_manifest')}
                                type='chose'
                                label="Dokumen Nikah"
                                customClass="w-full h-[350px]"
                                defaultUrl={formik.values?.data?.surat_nikah}
                                onChange={(url) => formik.setFieldValue('data.surat_nikah', url)}
                                handleRemove={() => formik.setFieldValue('data.surat_nikah', null)}
                                customName={`surat_nikah-${orderNumber}-${toPermalink(jamaahName)}`}
                                note={
                                    author?.surat_nikah
                                        ? `Terupload ${moment(author?.surat_nikah?.updated_at).format('dddd, DD MMMM YYYY [.] [jam] HH.mm')} oleh ${author?.surat_nikah?.name} sebagai ${author?.surat_nikah?.role}` 
                                        : null
                                }
                                />
                        </div>
                    </>
                )}
            </div>
            {permissions.includes('update_manifest') && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default Family