import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleGetDataJamaah } from 'stores/actions/booking'

import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import * as Yup from 'yup'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import { toPermalink } from 'utils/helpers'

const Akta = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber,
    author
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data: data
        }
    })
    const [isLoading, setIsLoading] = useState(false)
    

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(params.orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data Dokumen", code: 200 }))
            // dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }

    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })
        
    }, [])

    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <UploadArea
                    viewOnly={!permissions.includes('update_manifest')}
                    type='chose'
                    label="Dokumen Akta Kelahiran"
                    customClass="w-full h-[380px]"
                    defaultUrl={formik.values?.data?.akta_kelahiran}
                    onChange={(url) => formik.setFieldValue('data.akta_kelahiran', url)}
                    handleRemove={() => formik.setFieldValue('data.akta_kelahiran', null)}
                    customName={`akta_kelahiran-${orderNumber}-${toPermalink(jamaahName)}`}
                    note={
                        author?.akta_kelahiran
                            ? `Terupload ${moment(author?.akta_kelahiran?.updated_at).format('dddd, DD MMMM YYYY [.] [jam] HH.mm')} oleh ${author?.akta_kelahiran?.name} sebagai ${author?.akta_kelahiran?.role}` 
                            : null
                    }
                    />
            </div>
            {permissions.includes('update_manifest') && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default Akta