import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import Scroll from "components/scroll";
import Buttons from "components/buttons";

import IconSearch from "assets/icons/search-normal.svg";
import IconTanggal from "assets/icons/Tanggal.svg";
import IconTotalSeat from "assets/icons/total-seat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";
import IconSeatTersisaMaroon from "assets/icons/seat-tersisa-maroon.svg";
import ClockIcon from "assets/icons/colored/clock-icon.svg";
import AirplaneIcon from "assets/icons/colored/airplane-icon.svg";

import moment from "moment";

import { handlePackagePublishList } from "stores/actions/paket/landing";
import { AdminMemberAPI, AgenMemberAPI, JamaahMemberAPI } from "utils/api/member";

import useDebounce from "utils/helpers/useDebounce";
import Tab from "components/tab";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";
import Badge from "components/badge";
import SelectOrderer from "../SelectOrderer";

const Step1 = ({ formData }) => {
  const params = useParams();
  const dispatch = useDispatch()
  const auth = useSelector(({ auth }) =>auth);

  // GENERAL
  const [isLoadingList, setIsLoadingList] = useState(false)
  const [isLoadingMore, setIsloadingMore] = useState(false)

  // PACKAGE
  const [packageSelectorLabel, setPackageSelectorLabel] = useState("Pilih Paket")
  const [packageOptionsList, setPackageOptionsList] = useState([])
  const [nextPackageCursorList, setNextPackageCursorList] = useState(null)
  const [keywordPackage, setKeywordPackage] = useState("")
  const searchDebouncePackage = useDebounce(keywordPackage, 1000)

  // PEMESAN
  const [pemesanIsMe, setPemesanIsMe] = useState(false)
  const [pemesanSelectorLabel, setPemesanSelectorLabel] = useState("Pilih Pemesan")
  const [pemesanOptionsList, setPemesanOptionsList] = useState([])
  const [nextPemesanCursor, setNextPemesanCursor] = useState(null)
  const [keywordPemesan, setKeywordPemesan] = useState("")
  const searchDebouncePemesan = useDebounce(keywordPemesan, 1000)
  const [activeTab, setActiveTab] = useState("admin");
  const [selectMeStatus, setSelectMeStatus] = useState(false)

  const handlePackageTypeID = () => {
    if (params.packageType === 'haji') {
      return 1;
    }

    if (params.packageType === 'umroh') {
      return 2;
    }

    if (params.packageType === 'tour') {
      return 3;
    }

    if (params.packageType === 'jasabadal') {
      return 4;
    }

    if (params.packageType === 'tabungan') {
      return 5;
    }

  }

  // METHOD PACKAGE
  const handleGetPackageOptionsList = async (first = true) => {
    if(first) {
      setNextPackageCursorList(null)
      setIsLoadingList(true)
    }

    await dispatch(handlePackagePublishList({
      keyword: keywordPackage,
      paginate: 'cursor',
      per_page: 10,
      mode: 'options',
      package_type: handlePackageTypeID(),
      cursor: nextPackageCursorList
    }, (successData) => {
      setIsLoadingList(false)
      if (first) {
        setPackageOptionsList(successData.data)
      } else {
        let packageList = packageOptionsList;

        packageList.push.apply(packageList, successData.data)
        setPackageOptionsList(packageList)
      }
      setNextPackageCursorList(successData.next_cursor)
    }))
  }
  const handleSelectPackage = (packageData) => {
    formData.setFieldValue("paket.id", packageData.id_paket)
    formData.setFieldValue("paket.judul", packageData.judul_paket)

    setTimeout(() => {
      formData.setFieldTouched("paket.id", 1)
      formData.setFieldTouched("paket.judul", 1)
    })

    setPackageSelectorLabel(packageData.judul_paket.toUpperCase())
  }
  const handleLoadMorePackage = async () => {
    setIsloadingMore(true)
    await handleGetPackageOptionsList(false)
    setIsloadingMore(false)
  }

  // METHOD PEMESAN
  const handleGetPemesan = async (firstStep = false, type='admin') => {
    let params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options',
      'keyword': keywordPemesan
    }

    let response;

    if(!firstStep) {
      params['cursor'] = nextPemesanCursor
    }

    if(type == 'admin') {
      response = await AdminMemberAPI.getAdminList(params)
    }

    if(type == 'agent') {
      response = await AgenMemberAPI.getAgentList(params)
    }

    if(type == 'jamaah') {
      params = {
        ...params,
        have_ordered: 1
      }
      response = await JamaahMemberAPI.getJamaahList(params)
    }
    
    const { status, data }  = response

    if(status === 200) {
      if(firstStep) {
        setPemesanOptionsList(data.data)
      } else {
        let pemesanList = pemesanOptionsList

        pemesanList.push.apply(pemesanList, data.data)
        setPemesanOptionsList(pemesanList)
      }
      setNextPemesanCursor(data.next_cursor)
    }

  }
  const handleSelectPemesan = (pemesan, role) => {
    // console.log(pemesan.cabang_name, pemesan.color_bg_cabang, pemesan.color_text_cabang)
    formData.setFieldValue("pemesan.id", pemesan.id)
    formData.setFieldValue("pemesan.title", pemesan.title)
    formData.setFieldValue("pemesan.name", pemesan.name)
    formData.setFieldValue("pemesan.guard_type", role)

    if(role === 'jamaah'){
      formData.setFieldValue("pemesan.branch.name", auth.user.nama_cabang)
      formData.setFieldValue("pemesan.branch.bg_color", auth.user.color_bg_cabang)
      formData.setFieldValue("pemesan.branch.text_color", auth.user.color_text_cabang)
    }

    if(role === 'agent'){
      formData.setFieldValue("pemesan.branch.name", pemesan.cabang_name)
      formData.setFieldValue("pemesan.branch.bg_color", pemesan.color_bg_cabang)
      formData.setFieldValue("pemesan.branch.text_color", pemesan.color_text_cabang)
    }

    if(role === 'admin'){
      formData.setFieldValue("pemesan.branch.name", pemesan.nama_cabang)
      formData.setFieldValue("pemesan.branch.bg_color", pemesan.color_bg_cabang)
      formData.setFieldValue("pemesan.branch.text_color", pemesan.color_text_cabang)
    }
    
    setTimeout(() => {
      formData.setFieldTouched("pemesan.id", 1)
      formData.setFieldTouched("pemesan.title", 1)
      formData.setFieldTouched("pemesan.name", 1)
      formData.setFieldTouched("pemesan.guard_type", 1)
      formData.setFieldTouched("pemesan.branch.name", 1)
      formData.setFieldTouched("pemesan.branch.bg_color", 1)
      formData.setFieldTouched("pemesan.branch.text_color", 1)
    })

    setPemesanSelectorLabel(`${pemesan.title ? `${pemesan.title.toUpperCase()}.` : ''} ${pemesan.name.toUpperCase()}`)
    setSelectMeStatus(false)
  }
  const handleLoadMorePemesan = async (type) => {
    setIsloadingMore(true)
    if(type === 'admin') {
      await handleGetPemesan(false)
    }

    if(type === 'agent') {
      await handleGetPemesan(false, 'agent')
    }

    if(type === 'jamaah') {
      await handleGetPemesan(false, 'jamaah')
    }

    setIsloadingMore(false)
  }
  const handleSelectMe = () => {
    formData.setFieldValue("pemesan.id", auth.user.id)
    formData.setFieldValue("pemesan.title", auth.user.title)
    formData.setFieldValue("pemesan.name", auth.user.name)
    formData.setFieldValue("pemesan.guard_type", 'admin')

    formData.setFieldValue("pemesan.branch.name", auth.user.nama_cabang)
    formData.setFieldValue("pemesan.branch.bg_color", auth.user.color_bg_cabang)
    formData.setFieldValue("pemesan.branch.text_color", auth.user.color_text_cabang)
    
    setTimeout(() => {
      formData.setFieldTouched("pemesan.id", 1)
      formData.setFieldTouched("pemesan.title", 1)
      formData.setFieldTouched("pemesan.name", 1)
      formData.setFieldTouched("pemesan.guard_type", 1)
      formData.setFieldTouched("pemesan.branch.name", 1)
      formData.setFieldTouched("pemesan.branch.bg_color", 1)
      formData.setFieldTouched("pemesan.branch.text_color", 1)
    })
    
    setPemesanSelectorLabel(`${auth.user.title ? `${auth.user.title.toUpperCase()}.` : ''} ${auth.user.name.toUpperCase()}`)
    setSelectMeStatus(true)
  }

  useEffect(() => {
    setKeywordPemesan('')
  }, [activeTab])

  useEffect(() => {
    setNextPemesanCursor(null)
    setPemesanOptionsList([])
    switch (activeTab) {
      case "admin":
        handleGetPemesan(true);
        break;
      case "agent":
        handleGetPemesan(true, 'agent');
        break;
      case "jamaah":
        handleGetPemesan(true, 'jamaah');
        break;
      default:
        handleGetPemesan(true);
    }
  }, [activeTab, searchDebouncePemesan]);

  useEffect(() => {
    handleGetPackageOptionsList(true)
    handleGetPemesan()

    // HANDLE DEFAULT VALUE
    formData.values.paket.id && setPackageSelectorLabel(formData.values.paket.judul.toUpperCase())
    formData.values.pemesan.id && setPemesanSelectorLabel(`${formData.values.pemesan.title && `${formData.values.pemesan.title.toUpperCase()}.`} ${formData.values.pemesan.name.toUpperCase()}`)

    auth.user.id === formData.values.pemesan.id && 
      formData.values.pemesan.guard_type === 'admin' && setSelectMeStatus(true)

  }, [])

  useEffect(() => {
    handleGetPackageOptionsList(true)
  }, [searchDebouncePackage])

  return (
    <>
      
      <div>
        <div className="text-sm font-semibold">Paket</div>
        <Dropdowns
          customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
          placement="bottom"
          labelDropdown={packageSelectorLabel}
          dropdownArrow={true}
          error={formData.touched.packageID ? true : false}
          errorMsg={formData.touched.packageID && formData.errors.packageID}
        >
          
          <div className="bg-white w-full p-4 shadow-highlight rounded-2xl">
            <div className="pb-4">
              <Inputs
                id="searchPAckage"
                placeholder="Pencarian..."
                customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                iconPosition="left"
                onChange={(e) => setKeywordPackage(e.target.value)}
                icon={IconSearch}
              />
            </div>
            <Scroll 
              height="350px" 
              customClass="pr-2">
                <>
                  {packageOptionsList ? (
                    packageOptionsList.map((val, idx) => {
                      return (
                        <>
                          <div
                              key={idx}
                              className="flex flex-row bg-white rounded-2xl border-[0.5px] border-[#e0e0e0] py-2 px-4 mb-4 cursor-pointer"
                              onClick={() => {
                                handleSelectPackage(val);
                                closeDropdown();
                                setNextPackageCursorList(null)
                              }}
                            >
                              <div
                                className="card-detail-booking-image w-[104px] h-[104px] rounded-xl overflow-hidden"

                              >
                                <img src={val.image_thumbnail} className="object-cover h-full w-full" />
                              </div>
                              <div className="ml-4 flex flex-col gap-2 justify-center">
                                <div className="font-bold text-xl">
                                  {val.judul_paket}
                                </div>
                                <div className="flex flex-row gap-4 items-center">
                                  <div className="flex flex-row items-center gap-2">
                                    <img src={IconTanggal} className="w-5 h-5" alt="tanggal" />
                                    <div className="text-sm">
                                      {moment(val.jadwal_keberangkatan).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <img src={ClockIcon} className="w-5 h-5" alt="tanggal" />
                                    <div className="text-sm">
                                      {val.jumlah_hari} Hari
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <img src={AirplaneIcon} className="w-5 h-5" alt="tanggal" />
                                    <div className="text-sm">
                                      {val.maskapai?.name}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row gap-4 w-fit">
                                  <div className="flex flex-row items-center gap-2">
                                    <img className="w-6 h-6" src={IconTotalSeat} alt="" />
                                    <div className="black-linear-text !font-medium">{val.kuota}</div>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <img className="w-6 h-6" src={IconSeatTerisi} alt="" />
                                    <div className="blue-linear-text !font-medium">{val.jumlah_jamaah}</div>
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    {val.kuota_tersedia > 5 ? (
                                      <>
                                        <img className="w-6 h-6" src={IconSeatTersisa} alt="" />
                                      </>
                                    ) : (
                                      <>
                                        <img className="w-6 h-6" src={IconSeatTersisaMaroon} alt="" />
                                      </>
                                    )}
                                    <div className={`${val.kuota_tersedia > 5 ? `green-linear-text` : 'red-linear-text'} !font-medium`}>{val.kuota_tersedia}</div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          
                        </>
                      );
                    })
                  )
                  : (
                    <div className="mt-4">Paket Tidak ditemukan</div>
                  )}
                  {nextPackageCursorList && (
                    <>
                      <div className="flex justify-center items-center w-full m-4">
                        <Buttons
                          onClick={handleLoadMorePackage}
                          loading={isLoadingMore}
                          text="Lihat selengkapnya"
                          customClass="btn-outline btn-secondary btn-sm"
                          />
                      </div>
                    </>
                  )}
                </>
            </Scroll>
          </div>

        </Dropdowns>
      </div>
      
      <div>
        <div className="text-sm font-semibold mt-4">
          Pemesan { !selectMeStatus &&  <a className="inline-block text-red-1 cursor-pointer" onClick={handleSelectMe}>pilih diri saya</a> }
        </div>
        <Dropdowns
          customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center`}
          placement="bottom"
          labelDropdown={pemesanSelectorLabel}
          dropdownArrow={true}
        >
            <SelectOrderer handleChange={(orderer, role) => {
              handleSelectPemesan(orderer, role)
              closeDropdown()
            }} />
        </Dropdowns>
      </div>
      
    </>
  );
}

export default Step1