import { useEffect } from "react"
import { useParams } from "react-router"

import { useSelector } from "react-redux"

import PasPhoto4x6 from "./pas_photo_4x6"
import KTP from "./ktp";
import AktaKelahiran from './akta'
import Passport from "./passport";
import Family from "./family";
import VaccineCovid from "./vaccine_covid";
import Miningitiss from "./miningitis";
import BPJS from "./bpjs";
import Contact from "./contact";


const ManifestForm = ({
  manifestKey,
  dataManifest,
  onUpdate,
  jamaahData
}) => {
  const params = useParams()
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;
  return (
    <>
      {manifestKey === 'pas_photo_4x6' && (
        <PasPhoto4x6 
          data={dataManifest?.manifest?.pas_photo_4x6?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.pas_photo_4x6?.author}
          />
      )}
      {manifestKey === 'ktp' && (
        <KTP 
          data={dataManifest?.manifest?.ktp?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.ktp?.author}
          />
      )}
      {manifestKey === 'akta_kelahiran' && (
        <AktaKelahiran 
          data={dataManifest?.manifest?.akta_kelahiran?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.akta_kelahiran?.author}
          />
      )}
      {manifestKey === 'passport' && (
        <Passport 
          data={dataManifest?.manifest?.passport?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          auth={auth}
          author={dataManifest?.manifest?.passport?.author}
          />
      )}
      {manifestKey === 'family' && (
        <Family 
          data={dataManifest?.manifest?.family?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.family?.author}
          />
      )}
      {manifestKey === 'vaccine_covid' && (
        <VaccineCovid 
          data={dataManifest?.manifest?.vaccine_covid?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.vaccine_covid?.author}
          />
      )}
      {manifestKey === 'meningitis_vaccine' && (
        <Miningitiss 
          data={dataManifest?.manifest?.meningitis_vaccine?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          auth={auth}
          author={dataManifest?.manifest?.meningitis_vaccine?.author}
          />
      )}
      {manifestKey === 'bpjs' && (
        <BPJS 
          data={dataManifest?.manifest?.bpjs?.data}
          jamaahOrderID={dataManifest.order_kamar_mapping_id}
          onSuccess={onUpdate}
          permissions={permissions}
          jamaahName={dataManifest?.name}
          orderNumber={params.orderNumber}
          author={dataManifest?.manifest?.bpjs?.author}
          />
      )}
      {manifestKey === 'contact' && (
        <Contact 
          jamaahData={jamaahData}
          onSuccess={onUpdate}
          permissions={permissions}
          data={dataManifest?.manifest?.contact?.data}
          />
      )}
    </>
  )
}

export default ManifestForm